.section {
  padding: 20px;
}

.projectsList {
  list-style: none;
  margin: 0;
  padding: 0;
}

.projectContent {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
}

.triangleWrapper {
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.projectContent svg {
  width: 300px;
}

.leftArrow {
  &.projectContent svg {
    width: 300px;
    opacity: 0.9;
    transform: rotate(180deg);
  }
}

.projectVideoTitle {
  text-align: center;
  color: var(--light);
  letter-spacing: 2px;
  font-size: 24px;
  margin: 0;
  text-transform: uppercase;
  font-weight: normal;
}

.projectVideo {
  background: var(--light);
  border-radius: 20px;
  width: 300px;
  height: 400px;
}

.border {
  width: 3px;
  height: 250px;
  margin: 20px auto;
  background: var(--light);
  border-radius: 5px;
}
