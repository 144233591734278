.example {
  width: 40px;
  height: min-content;
  stroke: #bf4d00;
  border: 1px solid #eaeef5;
  color: #b8b8be;
}

.header {
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
}

.preview {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 400px;
}

.preview svg {
  width: 27%;
  height: 100%;
}

.titleWrapper {
  width: 73%;
  margin-bottom: 10px;
  text-align: center;
}

.companyTitle,
.slogan {
  margin: 0;
  color: #eaeef5;
  text-align: center;
  text-transform: uppercase;
  font-family: "Objectiv";
  font-weight: normal;
  font-size: 40px;
}

.slogan {
  letter-spacing: 1px;
  font-size: 16px;
  opacity: 0.8;
  font-family: "Objectiv";
}
