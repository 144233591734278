.projectsContainer {
  width: 100%;
  height: 100%;
}

.projectsList {
  max-width: 1350px;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  list-style: none;
  margin: 0 auto;
  padding: 0;
}

.projectsItem {
  width: 350px;
  height: 230px;
  padding: 17px;
  margin: 25px;
  border-radius: 20px;
  background: var(--light);
}

.projectsItemNameContainer {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
}

.projectsItemNameContainer svg {
  width: 20%;
  height: 100%;
}

.projectsItemName {
  width: 80%;
  font-size: 18px;
  margin: 0;
  text-wrap: balance;
}

.projectsItemDescription {
  padding: 10px;
  font-size: 18px;
}
