/* @font-face {
  font-family: "Objectiv";
  font-weight: 600;
  font-style: medium;
  src: url("./app/front/src/fonts/Objektiv-Medium.woff2") format("woff2"),
    url("./app/front/src/fonts/Objektiv-Medium.woff") format("woff");
} */

html {
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: radial-gradient(
    382.45% 155.51% at -7.04% -4.38%,
    #1b1e5b 6.71%,
    rgba(53, 58, 166, 0.85) 29.29%,
    rgba(55, 61, 196, 0.82) 50.36%,
    rgba(139, 72, 247, 0.61) 100%
  );
}

::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 20px;

  transition: background 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}

::-webkit-scrollbar-thumb:hover {
  background: #eaeef5;
}

body {
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-x: hidden;
  overflow-y: hidden;

  max-width: 1440px;
  height: 100%;
  background: radial-gradient(
    382.45% 155.51% at -7.04% -4.38%,
    #1b1e5b 6.71%,
    rgba(53, 58, 166, 0.85) 29.29%,
    rgba(55, 61, 196, 0.82) 50.36%,
    rgba(139, 72, 247, 0.61) 100%
  );

  --light: #eaeef5;
  --purple: #6967d5;
  --dark-blue: #1b1e5b;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
