.footer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 95%;
  margin: 0 auto;
  padding: 10px;

  border-radius: 20px 20px 0 0;
  background: var(--light);
  /* background: #3B376E; */
}

.logoWrapper {
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.logo {
  width: 80px;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;

  text-transform: uppercase;
  font-family: "Objectiv";
  font-size: 16px;
  text-decoration: none;
  color: var(--purple);

  transition: hover 2s;
}

.logo svg {
  width: 80px;
}

.logo path {
  fill: var(--purple);
}

.logo:hover path,
.logo:hover {
  fill: #8173e0;
  color: #8173e0;
}

.socialMediaList {
  list-style: none;
  margin: 0;
  padding: 0;

  display: flex;
  justify-content: space-between;
  width: 150px;
}

.socialMediaItem {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.socialMediaItem svg {
  width: 100%;
  height: 100%;
  fill: var(--purple);
  /* fill: var(--light); */
}

.shortcut {
  width: 180px;
  display: flex;
  flex-wrap: wrap;
}

.shortcutItem {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  color: var(--purple);
  margin: 5px 0;
  font-size: 16px;
}

.shortcutItem svg {
  width: 23px;

  fill: var(--purple);
}
